import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import NewsSection from '../components/homeNews'
import EventsSection from '../components/homeEvents'
import ColumnOverlayFocus from '../components/columnOverlayFocus'
import ColumnAlternatingStyled from '../components/columnAlternatingStyled'

const Index = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="intro-page">
        <Hero
          class="home-110"
          foreimage={post.frontmatter.foreimage.childImageSharp.gatsbyImageData}
          forealt={post.frontmatter.forealttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Learn more"
          ctaslug2="/contact/"
          ctatext2="Schedule a consultation"          
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#FFFFFF" ></path>
          </svg>
        </div>
      </div>

      <main id="main">

      <MDBContainer className="pb-100">
          <ColumnOverlayFocus focus={post.frontmatter.focus} />
        </MDBContainer>

        {post.frontmatter.section &&
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              <div className="about-overview">
                <h2 className="font-alt font-w-700 text-center letter-spacing-1 title-xs-medium title-extra-large pb-7">
                  Fully committed to your success
                </h2>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <ColumnAlternatingStyled
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={sections.btnlink}
                      colour={sections.btncolour}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }
        <EventsSection />
        <NewsSection />
      </main>
    </Layout>
  )
}
export default Index 

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.foreimage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      frontmatter {
        meta {
          title
          description 
        }          
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        foreimage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 3000, quality: 90)
          }
        }
        forealttext
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
        focus {
          title
          subtitle
          items {
            title
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(width: 90, quality: 90) 
              }
            }
            alttext
            link
            division
          }
        }
      }
    }
  }
`